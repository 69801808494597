<template>
  <div class="container text-center mt-5">
      <h1>Erro 404</h1>
      <p>A página que você tentou acessar não existe</p>
      <router-link to="/">Ir para início</router-link>
  </div>
</template>
<script>

export default {
  name: "error"
};
</script>
